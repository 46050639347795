import { uniqBy, sortBy, range, keyBy } from 'lodash';
import sgs1ServiceImage from '../../images/5-Year-544x247.png';
// import cps2ServiceImage from '../../images/five-year-service-new.png';
// import newWarrantyImage from '../../images/five-year-warranty-new.png';
// import oldImage from '../../images/five-year-warranty-old.png';

import {defaultNewService, defaultOldService} from '../content/service';
import {defaultNewWarranty, defaultOldWarranty} from '../content/warranty';

import * as actions from "../actions/index";

export default function servicingWarranty(state, action) {

  if (action.type === actions.SET_SERVICE_WARRANTY_ERROR) {
    return {
      ...state,
      error: action.error
    };
  }

  if (action.type === actions.SET_SERVICE_DATA) {
    let image = sgs1ServiceImage;
    switch (action.serviceProgramName) {
      case 'sgs1':
        image = sgs1ServiceImage;
        break;

      case 'cps2':
        image = sgs1ServiceImage;
        break;
    }
    // const image = action.serviceProgramName === 'sgs1' ? sgs1ServiceImage : newServiceImage;
    const defaultData = action.oldOrNew === 'new' ? defaultNewService : defaultOldService;
    const items = action.items.length > 0 ? action.items : defaultData.items;
    const disclaimers = action.disclaimers.length > 0 ? action.disclaimers : defaultData.disclaimers;

    return {
      ...state,
      serviceContent: {
        ...state.serviceContent,
        items,
        image, 
        disclaimers,
        terms: action.termsConditions
      }
    };
  }

  if (action.type === actions.SET_WARRANTY_DATA) {

    const image = action.oldOrNew === 'old' ? sgs1ServiceImage : sgs1ServiceImage;
    const defaultData = action.oldOrNew === 'new' ? defaultNewWarranty : defaultOldWarranty;
    const items = action.items.length > 0 ? action.items : defaultData.items;
    const disclaimers = action.disclaimers.length > 0 ? action.disclaimers : defaultData.disclaimers;

    return {
      ...state,
      warrantyContent: {
        logo: action.logo,
        items,
        image,
        disclaimers
      }
    };
  }

  if(action.type === actions.SET_FORM_VALUES) {

    const servicePricing = action.payload;

    const models = servicePricing ? servicePricing.map(item => {
      const modelName = item.model.trim();
      if (modelName) {
        return {
          value: modelName,
          label: modelName,
          oldOnly: item.old && !item.new ? true : false
        };
      }
    }).filter(model => (
      model !== undefined
    )) : [];

    const startYear = 2014;
    const endYear = new Date().getFullYear();
    const years = range(startYear, endYear + 1);

    const months = [
      {value: 1, label: "January"},
      {value: 2, label: "February"},
      {value: 3, label: "March"},
      {value: 4, label: "April"},
      {value: 5, label: "May"},
      {value: 6, label: "June"},
      {value: 7, label: "July"},
      {value: 8, label: "August"},
      {value: 9, label: "September"},
      {value: 10, label: "October"},
      {value: 11, label: "November"},
      {value: 12, label: "December"}
    ];

    return {
      ...state,
      servicePricing: {
        models: sortBy(uniqBy(models, 'value'), ['label']),
        years,
        months
      }
    };
  }

  if(action.type === actions.SET_SCHEDULE) {
    const data = action.payload;
    const vehicles = keyBy(data, 'model');
    return {
      ...state,
      vehicles
    };
  }

  if (action.type === actions.SET_TABLE_DATA) {

    let tableData;

    if (action.tableType === 'new' && action.variants) {
      const turbo = action.variants.filter(item => (item.turbo));
      const nonTurbo = action.variants.filter(item => (!item.turbo));
      tableData = {
        turbo,
        nonTurbo,
      };
    } else {
      tableData = action.variants;
    }

    return {
      ...state,
      tableData: {
        model: action.model,
        type: action.tableType,
        table: tableData,
        termsConditions: action.termsConditions,
        serviceCtas: action.serviceCtas
      }
    };
  }

  return state;
}