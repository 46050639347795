import React from 'react'
import propTypes from 'prop-types'
  
const QldOverlay = ({onClose}) => {
  return (
    <div className="react-qld-overlay">
      <div className="react-qld-overlay__inner">
        <p>You are now leaving suzuki.com.au and will be redirected to the Suzuki Queensland Website, suzukiqld.com.au</p>
        <p>Would you like to continue?</p>
        <div className="react-qld-overlay__ctas">
          <a href="http://suzukiqld.com.au" className="react-qld-overlay__ctas__cta">Yes, take me to suzukiqld.com.au</a>
          <a href="#" onClick={(e) => {onClose(); e.preventDefault()}} className="react-qld-overlay__ctas__cta react-qld-overlay__ctas__cta--no">No Thanks</a>
        </div>
      </div>
    </div>
  );
}
  
QldOverlay.propTypes = {
  onClose: propTypes.func.isRequired
}
  
export default QldOverlay