import React from 'react'
import propTypes from 'prop-types'

const Loader = ({absolute}) => {
  return (
    <div className={`sk-spinner sk-spinner-chasing-dots${absolute ? ' absolute' : ''}`}>
      <div className="sk-dot1"></div>
      <div className="sk-dot2"></div>
    </div>
  )
}
  
Loader.propTypes = {
  absolute: propTypes.bool
}

Loader.defaultProps = {
  absolute: true
}

export default Loader