import React from 'react';
import propTypes from 'prop-types';

const Radio = ({onChange, options, label, id, defaultOption}) => {
  return (
    <div className="react-radio">
      <span className="react-radio__label">{label}</span>
      {options.map((item, index) => (
        <div className="react-radio__item" key={`${id}_${item.value}`}>
          <input className="react-radio__input" name={id} id={`${id}_${item.value}`} type='radio' defaultChecked={index === defaultOption ? true : false} value={item.value} onChange={e => onChange(id, item.value)} />
          {item.label && <label className="react-radio__item__label" htmlFor={`${id}_${item.value}`}>{item.label}</label>}
        </div>
      ))}
    </div>
  );
};

Radio.propTypes = {
  onChange: propTypes.func.isRequired,
  options: propTypes.array.isRequired,
  defaultOption: propTypes.any,
};

Radio.defaultProps = {};

export default Radio;
