import React, { useEffect, useState, useRef } from "react";
import {get} from 'lodash';
import propTypes from 'prop-types';
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import qs from 'qs';
import axios from 'axios';

  
const TestDriveNurture = (props) => {

    const [startDate, setStartDate] = useState();
    const [paramError, setParamError] = useState(false);
    const [selected, setSelected] = useState([]);
    const [image, setImage] = useState(get(props, 'models[0].image', ''));
    const [submissionAttempt, setSubmissionAttempt] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const selectParent = useRef(null);

    const handleSelectClick = (e) => {
        // Fix for fast click bug
        setTimeout(() => {
            const inputs = selectParent.current ? selectParent.current.querySelectorAll('input[type="checkbox"]') : [];
            inputs.forEach(input => {
                input.classList.add("needsclick");
            });
        }, 0);
    };

    const [formErrors, setFormErrors] = useState({
        FirstName: false,
        LastName: false,
        Email: false,
        bookedInTestDrive: false,
        testDriveModel: false,
        testDriveDate: false
    });

    const [formState, setFormState] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        bookedInTestDrive: '',
        testDriveModel: '',
        testDriveDate: ''
    });

    useEffect(() => {
        if (submissionAttempt) {
            validate();
        }
    }, [formState]);

    useEffect(() => {
        if (!startDate) {
            setFormState({
                ...formState,
                testDriveDate: ''
            });
            return;
        }
        const yyyy = startDate.getFullYear();
        let mm = startDate.getMonth() + 1; // Months start at 0!
        let dd = startDate.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        //2022-03-12

        const date = yyyy + '-' + mm + '-' + dd;

        setFormState({
            ...formState,
            testDriveDate: date
        });
    }, [startDate]);

    useEffect(() => {
        setFormState({
            ...formState,
            testDriveDate: selected
        });
    }, [selected]);

    useEffect(() => {
        const queryParams = window.location.search.replace('?', '');
        const paramObj = queryParams.length > 0 ?  qs.parse(queryParams) : {};

        let firstname = '';
        let lastname = '';
        let email = '';

        const token = paramObj.token ? paramObj.token : null;

        console.log('token', token);

        if (token === null) {
            return;
        }
        //
        axios.get(`https://argbdm69t8.execute-api.ap-southeast-2.amazonaws.com/v1-0-1/test-drive-nurture?token=${token}`)
            .then((response) => {
                // handle success
                const data = (response.data && response.data.data) ? response.data.data : undefined;

                if (!data) {
                    setParamError(true);
                }

                const models = (data.model && props.models) ? data.model.split('and').map(item => item.trim()) : [];

                const modelsArr = models.filter(i => {
                    return props.models.find(j => j.title.toLowerCase() === i.toLowerCase());
                }).map(i => {
                    return {
                        label: i.charAt(0).toUpperCase() + i.slice(1),
                        value: i.charAt(0).toUpperCase() + i.slice(1),
                        disabled: false
                    };
                });

                setFormState({
                    ...formState,
                    FirstName: data.firstname,
                    LastName: data.lastname,
                    Email: data.email,
                    testDriveModel: modelsArr.length > 0 ? modelsArr.map(i => i.value).join(' and ') : ''
                });

                if (modelsArr.length > 0) {
                    setSelected(modelsArr);
                }
            }).catch(() => {
        
                setParamError(true);
            });
        //


        // if (!paramObj.firstname || !paramObj.lastname  || !paramObj.email ) {
        //     //setParamError(true);
        // } else {

        //}


    },[]);

    useEffect(() => {
        if (formState.FirstName) {
            setFormState({
                ...formState,
                testDriveModel: selected.map(i => i.label).join(' and ')
            });
            
        }

        if (selected.length > 0) {
            const firstSelected = props.models.find(i => i.title === selected[0].label);
            if (firstSelected) {
                setImage(firstSelected.image);
            }
        }
    },[selected]);
    
    const handleFormChange = (item, value) => {
        setFormState({
            ...formState,
            [item]: value
        });

        if (item === 'bookedInTestDrive') {
            setStartDate(null);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validate();
        console.log('is valud', isValid);
        setSubmissionAttempt(true);

        if (isValid) {
            let mkForm = MktoForms2.getForm(1227);

            mkForm.setValues(formState).addHiddenFields({
                formid: '1227',
                munchkinId: '700-XGA-517'
            });

            var valid = mkForm.validate();
            
            if (valid) {
                setSubmitting(true);
                mkForm.submit();
            }

            mkForm.onSuccess(function(values, followUpUrl) {
                window.location = '/test-drive-date/thankyou';
                setSubmitting(false);
                return false;
            });
        }
    };

    const validate = () => {
        const formItems = Object.keys(formState);
        let errors = {};
        formItems.forEach(item => {
            if (!formState[item] || formState[item].length === 0) {
                errors[item] = true;
            } else {
                errors[item] = false;
            }
        });
        setFormErrors(errors);

        if (formItems.find((item) => {
            if (item === 'testDriveDate' && formState.bookedInTestDrive && formState.bookedInTestDrive.toLowerCase() === 'no'){
                return false;
            } else {
                return errors[item] === true;
            }
        })) {
            return false;
        }
        return true;
    };
    
    return (
        <div className="row">
            {paramError === false && props.models ?
              <>
            <div className="columns small-12 medium-6 medium-push-6">
                <div className="nurture-landing-page__model-img" 
                    style={{
                        backgroundImage: `url("${image}")` 
                    }}
                ></div>
            </div>
            <div className="columns small-12 medium-6 medium-pull-6">
                <form className="form" action="#" onSubmit={handleSubmit}>
                    <div className="form__item">
                        <label>Name:</label>
                        <p>{formState.FirstName} {formState.LastName}</p>
                    </div>
                    <div className="form__item">
                        <label>Email:</label>
                        <p>{formState.Email}</p>
                    </div>
                    <div className={`form__item ${formErrors['testDriveModel'] ? 'has-error' : ''}`}>
                        <label>Model(s) Interested in test driving*<br /><small>Select up to 2</small></label>
                        <span ref={selectParent} onClick={handleSelectClick}>
                            <MultiSelect
                                className="react-select needsclick"
                                options={props.models.map(i => {
                                    let disabled = false;
                                    if (selected.length === 2 && !selected.find(j => j.value === i.title)) {
                                        disabled = true;
                                    }
                                    return {
                                        label: i.title,
                                        value: i.title,
                                        disabled
                                    };
                                })}
                                // isOpen={true}
                                hasSelectAll={false}
                                disableSearch={true}
                                value={selected}
                                onChange={setSelected}
                                label={"Select*"}
                            />
                        </span>
                    </div>
                    <div className={`form__item ${formErrors['bookedInTestDrive'] ? 'has-error' : ''}`}>
                        <label>Has a test drive been booked in?*</label>
                        <div className="form__item--radio-styled"> 
                            <div className="columns small-6">
                                <label className="plain-label">
                                    <input type="radio" value="Yes" name="bookedInTestDrive" className="form__item__field__input" onChange={(e) => handleFormChange('bookedInTestDrive', 'yes')}/>
                                    <span className="plain-label__text">Yes</span>
                                </label>
                            </div>
                            <div className="columns small-6">
                                <label className="plain-label">
                                    <input type="radio" value="No" name="bookedInTestDrive" className="form__item__field__input" onChange={(e) => handleFormChange('bookedInTestDrive', 'no')}/>
                                    <span className="plain-label__text">No</span>
                                </label>
                            </div>
                        </div> 
                    </div>
                    {formState.bookedInTestDrive.toLowerCase() === 'yes' &&
                        <div className={`form__item ${formErrors['testDriveDate'] ? 'has-error' : ''}`}>
                            <label>Date of Test Drive*</label>
                            <DatePicker 
                                selected={startDate} 
                                onChange={(date) => setStartDate(date)} 
                                useWeekdaysShort={true}
                                dateFormat="dd/MM/yyyy"
                                // minDate={new Date()}
                            />
                        </div>
                    }
                    <div className="form__item">
                        <button type="submit" className="cta cta--fill cta--fill-blue cta--full-small-only">{submitting ? 'Submitting...' : 'Submit'}</button>
                    </div>
                </form>
            </div>

              </>
                : 
                <div className="columns">
                    <h6 className="nurture-landing-page__error">Something went wrong. Please try again later</h6> 
                </div>
            }
        </div>
    );
};
  
TestDriveNurture.propTypes = {
};
  
TestDriveNurture.defaultProps = {
};
  
export default TestDriveNurture;