import React from 'react';
import propTypes from 'prop-types';

const Textfield = ({value, onChange, disabled, type, label, error}) => {

    return (
        <div className="react-textfield">
            {label && <span className="react-textfield__label">{label}</span>}
            <input className="react-textfield__input" type={type} value={value} onChange={e => onChange(e)} disabled={disabled} label={label} />
            {error && <span className="react-error">{error}</span> }
        </div>
    );
};

Textfield.propTypes = {
    type: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    defaultOption: propTypes.any,
    placeholder: propTypes.string,
    error: propTypes.any
};

Textfield.defaultProps = {
    error: false
};

export default Textfield;
