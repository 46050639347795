import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';

const ServiceTable = ({data, model, modelType}) => {


  const [accordionIndex, setAccordionIndex] = useState(null);

  const handleAccordionClick = (index, e) => {
    e.preventDefault();
    if (index === accordionIndex) {
      setAccordionIndex(null)
    } else {
      setAccordionIndex(index);
    }
  }

  useEffect(() => {
    setAccordionIndex(null);
  }, [data])

  console.log('d' ,data);

  return (
    <div className="servicing-table-wrapper">
        <table>
            <thead>
                <tr>
                    <th colSpan="2">{modelType}</th>
                    {data.map((item, index) => (
                        <th colSpan="2" key={index}>{model} {item.modelVariant}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data[0].intervals.map((item, index) => {
                  const intervalItem = item;
                  return (
                    <>
                      <tr key={`tr_${index}`}>
                          <td colSpan="2">
                            <div>
                              {item.text &&
                                  <React.Fragment>
                                      <span dangerouslySetInnerHTML={{__html: item.text}} />
                                  </React.Fragment>
                              }                            
                            </div>
                          </td>
                          {data.map((item, tdIndex) => {
                            return(
                              <td colSpan="2" key={tdIndex}>
                                <div>
                                  {item.intervals[index].price}
                                  {/* {(item.intervals[index].priceDisclaimer && accordionIndex === index) && <p className={`service-table__disclaimer`}>{item.intervals[index].priceDisclaimer}</p>} */}
                                  {(intervalItem.accordion && intervalItem.accordion.length > 0) && <a href="#" className={`service-table__accordion-btn${accordionIndex === index ? ' is-active' : ''}`} onClick={(e) => handleAccordionClick(index,e)}>{accordionIndex === index ? 'Close' : 'View details'}</a>}
                                </div>
                              </td>
                          )})}
                      </tr>
                      {((item.accordion && item.accordion.length > 0) && accordionIndex === index) &&
                        <tr>
                          {item.accordion.map((accordionItem, index2) => {
                            return accordionItem ? (
                                <td className="servicing-table__accordion-td" colSpan={index2 > 1 ? '2' : '1'} key={`tr_accordion${index}_${index2}`}>
                                  <div>
                                    {accordionItem.title &&  
                                      <h6>{accordionItem.title}</h6>
                                    }
                                    {accordionItem.text &&
                                        <React.Fragment>
                                            <span dangerouslySetInnerHTML={{__html: accordionItem.text}} />
                                        </React.Fragment>
                                    }                            
                                  </div>
                                </td>
                            ) : null
                          })}
                        </tr>
                      }
                    </>
                )})}
            </tbody>
        </table>
    </div>
  );
};
  
ServiceTable.propTypes = {
  data: propTypes.array.isRequired,
  model: propTypes.string.isRequired,
  modelType: propTypes.string
};
  
ServiceTable.defaultProps = {
  modelType: ' '
};

export default ServiceTable;