import * as actions from '../actions/index';
import {get} from 'lodash';
import roundNumber from '../utils/roundNumber'

export default function repaymentCalculator(state, action) {
    if (action.type === actions.SET_REPAYMENT_CALC_VAL) {

        const fields = state.fields.map(item => {
            if (action.field === 'deposit' && item.id === 'deposit') {
                if (action.value > item.max) {
                    return {
                        ...item,
                        value: item.max
                    }
                }
            }
            if (item.id === 'deposit' && action.field === 'amount') {
                return {
                    ...item,
                    max: action.value * 0.5 // 50% of value
                };
            }
            if (item.id === 'balloon') {
                // if (action.field === 'amount') {
                //   // const amountField = state.fields
                //   return {
                //       ...item,
                //       max: action.value - get(state, 'fieldObj.deposit', 0),
                //       value: action.value * 0.2
                //   };
                // }
                if (action.field === 'deposit') {
                  const max = Math.abs(get(state, 'fieldObj.amount', 0) - action.value)
                  return {
                      ...item,
                      max
                  };
                }
            }
            if (item.id === action.field) {
                return {
                    ...item,
                    value: action.value
                };
            }
            return item;
        });

        let fieldObj = {};

        fields.forEach(field => {
          fieldObj[field.id] = field.value;
        });

        let futureVal = fieldObj.balloon === 0 ? 1 : fieldObj.balloon || 8880;

        // if (fieldObj.modelVariant && fieldObj.modelVariant.futureValue) {
        //     const terms = fieldObj.modelVariant.futureValue.find(term => {
        //         return parseInt(term.months.trim()) === fieldObj.loanTerm
        //     })
        //     //console.log('terms', terms);
        //     if (terms && terms.kmsPerYear) {
        //         const getFutureVal = terms.kmsPerYear.find(km => {
        //             return parseInt(km.kms) === parseInt(fieldObj.travelAllowance)
        //         })

        //         if (getFutureVal && getFutureVal.gfv) {
        //             futureVal =  parseInt(getFutureVal.gfv)
        //         }
        //     }
        // }

        const vpp = fieldObj.amount; //c4
        const deposit = isNaN(fieldObj.deposit) ? 0 : (fieldObj.deposit); //c5
        const interestRate = isNaN(fieldObj.interest) ? 0 : fieldObj.interest; //c6
        const tol = fieldObj.loanTerm / 12; //c7

        const gfv = futureVal; //c9
        const totalLoanAmount = vpp - deposit; //c10
        const estFee = fieldObj.estFee || 499; //c11
        const weeklyAccKeepingFee = fieldObj.weeklyAccKeepingFee || 1.5 // c20
        const monthlyAccKeepingFee = fieldObj.monthlyAccKeepingFee || 8 // d20

        const rate = (interestRate == 0 ? 0.001 : interestRate / 1000) / 12; //d15
        const noOfRepayments = tol * 12; //d16
        const pv = totalLoanAmount + estFee; //d17
        const fv = gfv; //d18
        const repaymentVal = ((pv - fv / Math.pow(1 + rate, noOfRepayments)) * rate) / (1 - Math.pow(1 + rate, -noOfRepayments));
        
        return {
            ...state,
            fieldObj,
            balloon: fieldObj.balloon,
            fields,
            totalLoanAmount,
            weeklyAccKeepingFee,
            monthlyAccKeepingFee,
            weeklyRate: ((interestRate == 0 ? 1 : interestRate / 10) / 52),
            monthlyRate: ((interestRate == 0 ? 1 : interestRate / 10) / 12),
            weeklyPmtIncFee: (repaymentVal / 4.33) + weeklyAccKeepingFee,
            monthlyPmtIncFee: repaymentVal + monthlyAccKeepingFee,
            weeklyRepaymentVal: repaymentVal / 4.33,
            monthlyRepaymentVal: repaymentVal,
            estFee,
            interestRate: roundNumber(interestRate / 10),
            gfv,
            totalMonthlyRepayments: Math.ceil(noOfRepayments),
            totalWeeklyRepayments: Math.ceil(noOfRepayments * 4.33)
        };
    }
    return state;
}
