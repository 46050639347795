import numberWithCommas from '../utils/numberWithCommas';

const defaultField = {
    min: 0,
    value: 30000,
    max: 60000,
    step: 1000,
    type: 'slider'
};

const fields = [
    {
        id: 'postcode'
    },
    {
        id: 'futureValue'
    },
    {
        id: 'model'
    },
    {
        id: 'modelVariant'
    },
    {
        id: 'travelAllowance'
    },
    {
        id: 'estFee'
    },
    {
        id: 'weeklyAccKeepingFee'
    },
    {
        id: 'monthlyAccKeepingFee'
    },
    // Loan Amount
    {
        ...defaultField,
        id: 'amount',
        title: 'Vehicle purchase price*',
        beforeVal: '$',
        type: 'text',
        disabled: true,
        format: numberWithCommas
    },
    // Deposit Amount
    {
        ...defaultField,
        id: 'deposit',
        title: 'Deposit (optional)',
        beforeVal: '$',
        format: numberWithCommas,
        value: 0,
        max: 30000
    },
    // Balloon Amount
    {
        ...defaultField,
        id: 'balloon',
        title: 'Balloon Amount',
        beforeVal: '$',
        format: numberWithCommas,
        value: 0,
        max: 30000
    },
    // Interest
    {
        ...defaultField,
        id: 'interest',
        title: 'Interest rate p.a.',
        afterVal: '%',
        min: 0,
        max: 200,
        step: 1,
        value: 89,
        valType: 'percentage'
    },
    // Loan Term
    {
        ...defaultField,
        id: 'loanTerm',
        title: 'Loan Term',
        type: 'select',
        value: 60,
        options: [
            {
                label: '1 Year',
                value: 12
            },
            {
                label: '2 Years',
                value: 24
            },
            {
                label: '3 Years',
                value: 36
            },
            {
                label: '4 Years',
                value: 48
            },
            {
                label: '5 Years',
                value: 60,
                default: true
            },
            {
                label: '6 Years',
                value: 72
            },
            {
                label: '7 Years',
                value: 84
            }
        ]
    }
];

export default fields;
