import React from 'react';
import propTypes from 'prop-types';
import Slider from 'rc-slider';
import AutosizeInput from 'react-input-autosize';
import roundNumber from '../../utils/roundNumber'
class RangeField extends React.Component {

  constructor(props) {
    super(props)
    this.inputRef = React.createRef();
    this.handleInputUpdate = this.handleInputUpdate.bind(this)
    this.state = {
      inputVal: this.props.value,
      calcVal: (this.props.value / 10).toFixed(2)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const val = this.props.value / 10
      this.setState(state => ({ ...state, inputVal: this.props.format(this.props.value), calcVal: roundNumber(val) }));
    }
  }

  handleInputUpdate(e) {
    const v = e.target.value && e.target.value.length > 0 ? e.target.value : min;
    const newCalcVal = this.props.valType === 'percentage' ? (v * 10).toFixed(2) : v
    const trimVal = parseFloat(newCalcVal.replace(',', ''))
    const val = trimVal > this.props.max ? this.props.max : trimVal
    const calcVal = this.props.value / 10
    this.setState(state => ({ ...state, inputVal: this.props.format(val), calcVal: roundNumber(calcVal) }));
    this.props.handleChange(typeof newCalcVal === 'string' ? val : parseFloat(newCalcVal));
  }

  render() {
    const {title, min, max, value, handleChange, beforeVal, afterVal, input, inputType, valType, maxLength} = this.props
    return (
      <div className="range-field">
        <div className="range-field__header">
          <span className="range-field__title" dangerouslySetInnerHTML={{__html: title}}/>
          {input ?
            <span className="range-field__value" onClick={() => this.inputRef.current.focus()}>
              <span className="range-field__value__before">{beforeVal}</span>
              <AutosizeInput
                className="range-field__input"
                name="form-field-name"
                value={valType === 'percentage' ? this.state.calcVal : this.state.inputVal}
                style={{ fontSize: 20 }}
                ref={this.inputRef}
                onChange={
                  (e) => {
                    const v = e.target.value && e.target.value.length > 0 ? e.target.value : ''
                    const eVal = e.target.value.replace(',', '');
                    if (eVal.length <= maxLength || (eVal.length < this.state.inputVal.length)) {
                      this.setState(state => ({ ...state, calcVal: v, inputVal: v }));
                    }
                  }
                }
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    this.handleInputUpdate(e)
                  }
                }} 
                onBlur={
                  (e) => {
                    this.handleInputUpdate(e)
                  }
                }
                type={inputType}
              />
              <span className="range-field__value__after">{afterVal}</span>
            </span>
          :
            <span className="range-field__value">
              <span className="range-field__value__before">{beforeVal}</span>
              <span className="range-field__value__value">{val}</span>
              <span className="range-field__value__after">{afterVal}</span>
            </span>
          }
        </div>
        <Slider
          min={min}
          max={max}
          value={value}
          onChange={(val) => {
            handleChange(val);
            const calcVal = valType === 'percentage' ? val / 10 : val
            this.setState(state => ({ ...state, calcVal: calcVal, inputVal: val }));
          }}
          onAfterChange={(val) => handleChange(val)}
        />
      </div>
    )
  }
}
  
RangeField.propTypes = {
  title: propTypes.string,
  min: propTypes.number,
  max: propTypes.number,
  value: propTypes.any,
  handleChange: propTypes.func,
  beforeVal: propTypes.string,
  afterVal: propTypes.string,
  format: propTypes.func,
  input: propTypes.bool,
  inputType: propTypes.string,
  maxLength: propTypes.number
}
  
RangeField.defaultProps = {
  title: null,
  min: 0,
  max: 100000,
  value: 0,
  handleChange: () => {},
  beforeVal: '',
  afterVal: '',
  format: (val) => {return val},
  input: false,
  inputType: 'text',
  maxLength: 5
}
  
export default RangeField