import React from 'react';
import propTypes from 'prop-types';
import Dropdown from 'react-dropdown';

class ModelForm extends React.Component {

  constructor(props) {
  	super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      modelValue: null,
      monthValue: null,
      yearValue: null,
      activeModel: null,
      yearEnabled: false,
      monthEnabled: false
    };
  }

  onChange(type, val) {
    if (type === 'modelValue') {
      const model = this.props.models.find(item => {
        return item.value === val.value
      })
      const yearItems = model.oldOnly ? this.props.years.filter(year => (
        year <= 2019
      )) : this.props.years
      this.setState({
        yearEnabled: true,
        activeModel: model,
        yearValue: null,
        monthValue: null,
        monthEnabled: false,
        yearItems
      })
    }
    if (type === 'yearValue') {
      // don't show future months
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const monthItems = (val.value === 2019) ? [...this.props.months].slice(9) : ((val.value === currentYear ? this.props.months.slice(0, currentMonth) : this.props.months))
      console.log('yearValue', monthItems, val, this.props.months);

      this.setState({
        monthItems,
        monthEnabled: true,
        monthValue: null,
      })
    }
    this.setState({ [type] : val });
  }

  onSubmit() {
    const {
      modelValue,
      monthValue,
      yearValue
    } = this.state;

    // if (modelValue && monthValue && yearValue) {
    if (modelValue) {
      const values = {
        model: modelValue.value,
        // month: monthValue.value,
        // year: yearValue.value
      };
      this.props.onSubmit(values, this.state.servicePricing);
    } else {
      this.setState({
        showErrors: true
      });
    }
  }
  
  render() {

    const {
      title
    } = this.props;

  	return(
      <div className={`model-form${this.props.error ? ' has-error' : ''}`}>
        {title && <h4>{title}</h4> }
        <hr />
        <div className="model-form__row">
          <div className="model-form__col-large">
            <label>Model</label>
            <Dropdown
              options={this.props.models}
              value={this.state.modelValue}
              disabled={this.props.models ? false: true}
              onChange={(val) => this.onChange('modelValue', val)}
              placeholder="Select a model" />
              {!this.state.modelValue && this.state.showErrors &&
                <span className="model-form__error">Please select a model</span>
              }
          </div>
          {/* <div className="model-form__col-small">
            <label>Year</label>
            <Dropdown
              options={this.state.yearItems || this.props.years}
              value={this.state.yearValue}
              disabled={!this.state.yearEnabled}
              placeholder="Select a year"
              onChange={(val) => this.onChange('yearValue', val)}
              />
            {!this.state.yearValue && this.state.showErrors &&
              <span className="model-form__error">Please select a year</span>
            }
          </div>
          <div className="model-form__col-small">
            <label>Month</label>
            <Dropdown
              disabled={!this.state.monthEnabled}
              options={this.state.monthItems || this.props.months}
              value={this.state.monthValue}
              onChange={(val) => this.onChange('monthValue', val)}
              placeholder="Select a month" />
              {!this.state.monthValue && this.state.showErrors &&
                <span className="model-form__error">Please select a month</span>
              }
          </div> */}
          <div className="model-form__col-small">
            <button type="button" className="cta cta--fill model-form__submit-btn" onClick={this.onSubmit}>Submit</button>
          </div>
        </div>
        {this.props.error &&
          <span className="model-form__error model-form__error--large-margin">{this.props.error}</span>
        }
      </div>
  	);
  }
}

ModelForm.propTypes = {
  title: propTypes.string,
  error: propTypes.string,
  onSubmit: propTypes.func.isRequired,
};
  
ModelForm.defaultProps = {
  title: null,
  error: null
};

export default ModelForm;
