const roundNumber = (number, decimals) => {
  const dec = decimals || 2
  const arr = number.toString().split('.')
  const num = arr[0]

  if (arr.length === 1) {
    return Number(num + '.' + '00')
  }

  const decimalP1 = arr[1].length < dec ? arr[1] + 0 : arr[1]
  const decimal = decimalP1.substring(0, dec);
  return Number(num + '.' + decimal)
};

export default roundNumber