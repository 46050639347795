import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import Dropdown from 'react-dropdown';

const Select = ({options, onChange, defaultOption, placeholder, label, value}) => {
    const def = defaultOption ? defaultOption : options[0]
    const [val, setVal] = useState(def || options[0]);
    useEffect(() => {
        setVal(def);
    }, [def]);
    return (
        <div className="react-dropdown">
            {label && <span className="react-dropdown__title">{label}</span>}
            <Dropdown
                options={options}
                onChange={val => {
                    setVal(val);
                    onChange(val);
                }}
                value={value || val}
                placeholder={placeholder}
            />
        </div>
    );
};

Select.propTypes = {
    options: propTypes.array.isRequired,
    onChange: propTypes.func.isRequired,
    defaultOption: propTypes.any,
    placeholder: propTypes.string
};

Select.defaultProps = {
    defaultOption: null,
    placeholder: 'Select an option'
};

export default Select;
