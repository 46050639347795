import axios from 'axios';
import { get } from 'lodash';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_SERVICE_WARRANTY_ERROR = 'SET_SERVICE_WARRANTY_ERROR';
export const SET_WARRANTY_DATA = 'SET_WARRANTY_DATA';
export const SET_SERVICE_DATA = 'SET_SERVICE_DATA';
export const SET_REPAYMENT_CALC_VAL = 'SET_REPAYMENT_CALC_VAL';

// import testServiceWarrantyData from '../data/test-service-warranty.json';

const servicingWarrantyAPI = (window.location.host === 'suzukiqld.com.au.localhost' || window.location.host === 'localhost') ? 'https://nayjuzms6d.execute-api.ap-southeast-2.amazonaws.com/v1-0-1?url=https://client-d-suzukiqld-com-au.pantheonsite.io/wp-json/warranty-service-pricing/v1/data' : '/wp-json/warranty-service-pricing/v1/data';

export function getServicingWarranty() {

    return dispatch => {

      axios.get(servicingWarrantyAPI).then(response => {
        const data = response.data;

        dispatch({
          type: SET_FORM_VALUES,
          payload: data
          // payload: testServiceWarrantyData // data
        });

        dispatch({
          type: SET_SCHEDULE,
          payload: data
          // payload: testServiceWarrantyData // data
        });

      }).catch(function (error) {
        // handle error
        console.error('Error: ', error);
        dispatch({
          type: SET_SERVICE_WARRANTY_ERROR,
          error: "Sorry, something went wrong. Please try again later."
        });
      });
    };
}

export function getTableData(type, model) {

  return (dispatch, getState) => {
    const vehicles = getState().servicingWarranty.vehicles;
    const {
      year,
      month,
      oldOrNew
    } = type

    const months = [
      {value: 1, label: "January"},
      {value: 2, label: "February"},
      {value: 3, label: "March"},
      {value: 4, label: "April"},
      {value: 5, label: "May"},
      {value: 6, label: "June"},
      {value: 7, label: "July"},
      {value: 8, label: "August"},
      {value: 9, label: "September"},
      {value: 10, label: "October"},
      {value: 11, label: "November"},
      {value: 12, label: "December"}
    ];

    console.log('get(vehicles, `[${model}].years`, [])', get(vehicles, `[${model}].years`, []))

    // find vehicle with year and month
    var _vehicle = get(vehicles, `[${model}].years`, []).find(item => {

      // if year less than
      if (!item.from && year < parseInt(item.to.year)) {
        return true
      }

      // if first and same year - check month
      if (!item.from && year === parseInt(item.to.year)) {
        const monthVal = months.find(i => i.label.toLowerCase() === item.to.month.toLowerCase());
        if (month <= monthVal.value) {
          return true
        }
      }

      // if after same year - check month
      if (!item.to && year === parseInt(item.from.year)) {
        const monthVal = months.find(i => i.label.toLowerCase() === item.from.month.toLowerCase());
        if (month >= monthVal.value) {
          return true
        }
      }
      // if year greater than
      if (!item.to && year > parseInt(item.from.year)) {
        return true
      }

      // Between
      if (item.from && item.to) {

        const monthValFrom = months.find(i => i.label.toLowerCase() === item.from.month.toLowerCase());
        const monthValTo = months.find(i => i.label.toLowerCase() === item.to.month.toLowerCase());

        // If both year values are the same check month values
        if (year === parseInt(item.from.year) && year === parseInt(item.to.year)) {
          if ((month >= monthValFrom.value) && (month <= monthValTo.value)) {
            return true
          }
        }
        
        // If the same year and month value is greater or equal to
        if (year === parseInt(item.from.year)) {
          if ((month >= monthValFrom.value)) {
            return true
          }
        } 
        
        // If the same year and month value is less or equal to
        if (year === parseInt(item.to.year)) {
          if ((month <= monthValTo.value)) {
            return true
          }
        } 

        // If year from & to are different don't bother checking the month
        if ((year > parseInt(item.from.year)) && (year < parseInt(item.to.year))) {
          return true
        }

      }

      return false

    });

    var vehicle = get(vehicles, `[${model}].years`, [])[0]

    console.log('vehicles', {vehicles, model, year});
    console.log('vehicle', vehicle);

    // const vehicle = get(vehicles, `[${model}][${year}]`, null);
    const variants = get(vehicle, 'variants', null);

    dispatch({
      type: SET_SERVICE_DATA,
      serviceProgramName: get(vehicle, 'serviceProgram.name', null),
      items: get(vehicle, 'serviceProgram.items', []),
      disclaimers: get(vehicle, 'serviceProgram.disclaimers', []),
      oldOrNew,
      termsConditions: get(vehicle, 'termsConditions', null),
    });

    dispatch({
      type: SET_WARRANTY_DATA,
      items: get(vehicle, 'warranty.items', []),
      disclaimers: get(vehicle, 'warranty.disclaimers', []),
      oldOrNew,
    });

    dispatch({
      type: SET_TABLE_DATA,
      variants,
      model,
      tableType: oldOrNew,
      serviceCtas: get(vehicle, 'serviceCtas', [])
    });

  };

}

export function updateRepaymentCalcVal(field, value) {
  return dispatch => {
    dispatch({
      type: SET_REPAYMENT_CALC_VAL,
      field,
      value,
    });
  }
}