import newImage from '../../images/five-year-warranty-new.png';
import oldImage from '../../images/five-year-warranty-old.png';

const defaultNewWarranty = {
  image: newImage,
  items: [
    'Warranty available for 5 years (unlimited km)*.',
    'The warranty applicable to vehicles used in commercial applications (taxis, Uber, transportation of goods for payment, rideshare, driver instruction vehicles etc) will be limited to 5 years/160,000 km, whichever occurs first.',
  ],
  disclaimers: ['*For Warranty terms and conditions please consult your vehicles Warranty and Service Record Booklet.']
};

const defaultOldWarranty = {
  image: oldImage,
  items: [
    'Warranty available for 3 years or 100,000km (whichever comes first)*.',
    "By servicing your Suzuki vehicle every 6 months for 5 years via Suzuki's Capped Price Service program, your vehicle is eligible for a 5 year / 140,000km extended warranty.^",
  ],
  disclaimers: [
    "*For Warranty terms and conditions please consult your vehicles Warranty and Service Record Booklet.",
    "^Please find the terms and conditions for Suzuki's extended warranty program <a href='/owners/warranty' target='_blank'>here</a>."
  ]
};

export { defaultNewWarranty, defaultOldWarranty };