import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import axios from 'axios';

import * as actions from '../actions/index';
import RangeField from '../components/calculators/RangeField';
import Select from '../components/ui/Select';
import TextField from '../components/ui/Textfield';
import Summary from '../components/calculators/Summary';
import Radio from '../components/ui/Radio';
import QldOverlay from '../components/content/QldOverlay';
import Loader from '../components/ui/Loader';
import getUrlParams from '../utils/getUrlParams'

class RepaymentCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.onFieldChange = this.onFieldChange.bind(this);
        this.grabPostCodes = this.grabPostCodes.bind(this);
        this.handleVariantChange = this.handleVariantChange.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleTravelAllowance = this.handleTravelAllowance.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.goBackUrl = this.goBackUrl.bind(this);

        this.state = {
            activeState: undefined,
            postcode: '',
            postcodeData: [],
            postcodeSuggestions: [],
            activeModel: undefined,
            activeModelObj: undefined,
            activeVariant: undefined,
            activeVariantObj: undefined,
            allowance: null,
            showSummary: false,
            qldOverlay: false,
            postcodeError: false,
            showDisclaimer: true,
            goBackUrl: this.goBackUrl()
        };
    }

    componentDidMount() {
        this.props.dispatch(actions.updateRepaymentCalcVal());
        this.grabPostCodes();

        if (this.props.defaultdeposit.length > 0) {
            this.props.dispatch(actions.updateRepaymentCalcVal('deposit', parseInt(this.props.defaultdeposit, 10)));
        }
        if (this.props.defaultinterestrate.length > 0) {
            const iRate = parseFloat(this.props.defaultinterestrate)
            this.props.dispatch(actions.updateRepaymentCalcVal('interest', iRate));
        }
        const defaultModel = this.props.pricing['Tasmania'].models[0]
        let price = defaultModel.variants[0].price;

        this.props.dispatch(actions.updateRepaymentCalcVal('amount', price));
        this.props.dispatch(actions.updateRepaymentCalcVal('model', defaultModel));
        this.props.dispatch(actions.updateRepaymentCalcVal('modelVariant', defaultModel.variants[0]));
        // If future value dropdown exists then set to 10000 else set to max of 25000
        this.props.dispatch(actions.updateRepaymentCalcVal('travelAllowance', this.props.futurevalue ? 10000 : 25000));
        this.props.estfee && this.props.dispatch(actions.updateRepaymentCalcVal('estFee', parseFloat(this.props.estfee)));
        this.props.weeklyacckeepingfee && this.props.dispatch(actions.updateRepaymentCalcVal('weeklyAccKeepingFee', parseFloat(this.props.weeklyacckeepingfee)));
        this.props.monthlyacckeepingFee && this.props.dispatch(actions.updateRepaymentCalcVal('weeklyAccKeepingFee', parseFloat(this.props.monthlyacckeepingFee)));

    }

    goBackUrl() {
        const ref = getUrlParams().ref
        const url = !ref ? '/' : ref // Get ref and remove hash router value
        return url
    }

    handleTravelAllowance(obj) {
        this.setState({allowance: obj}, () => {
            this.props.dispatch(actions.updateRepaymentCalcVal('travelAllowance', obj.value))
        })
    }

    onFieldChange(field, value) {
        this.props.dispatch(actions.updateRepaymentCalcVal(field, value));
    }

    handleVariantChange(variant) {

        const varaintObj = this.state.activeModelObj.variants.find(vari => {
            return vari.name === variant.label
        })

        this.setState({activeVariant: variant}, () => {
            const {activeState, activeModel, activeVariant} = this.state;
            let price = this.getPrice(activeState, activeModel ? activeModel.value : 0, activeVariant.value);
            this.props.dispatch(actions.updateRepaymentCalcVal('amount', price));
            this.props.dispatch(actions.updateRepaymentCalcVal('modelVariant', variant));
        });
    }

    handleModelChange(model) {


        const modelObj = this.props.pricing[this.state.activeState].models.find(mod => {
            return mod.name === model.label
        })

        const variant = get(modelObj, 'variants[0]', null)

        const newVariant = {
            value: 0,
            label: get(modelObj, 'variants[0].name', null),
            futureValue: get(modelObj, 'variants[0].futureValue', null)
        }

        this.setState({activeModel: model, activeVariant: variant ? newVariant : this.state.activeVariant}, () => {
            const {activeState, activeModel, activeVariant} = this.state;
            let price = this.getPrice(activeState, activeModel.value, 0);

            this.props.dispatch(actions.updateRepaymentCalcVal('amount', price));
            this.props.dispatch(actions.updateRepaymentCalcVal('model', modelObj));
            this.props.dispatch(actions.updateRepaymentCalcVal('modelVariant', activeVariant));
        });
    }

    getPrice(state, model, variant) {
        return this.props.pricing[state ? state : 0].models[model ? model : 0].variants[variant ? variant : 0].price;
    }

    grabPostCodes() {

        axios.get('/wp-content/themes/suzuki/data/postcodes.json')
            .then((response) => {
                // handle success
                this.setState({postcodeData: response.data});
            }).catch(() => {
                console.log('could not load postcodes')
            })
    }

    updatePostcode(value) {
        if (value.length < 5) {
            this.setState({postcode: value}, () => {
                let postcode = this.state.postcode;
                let postcodeData = this.state.postcodeData;
                if (postcode.length > 3) {
                    let suggestion = postcodeData.find(item => {
                        let location = item['Postal Code'].toString();
                        return location === postcode;
                    });
                    if (suggestion) {
                        if (suggestion.State.toLowerCase() !== 'queensland') {

                            const fieldOjb = this.props.repaymentCalculator.fieldObj
                            const stateModels = this.props.pricing[suggestion.State]
                            
                            const stateModel = this.state.activeModelObj && fieldOjb.model && stateModels ? stateModels.models.find(model => {
                                return model.name === fieldOjb.model.name
                            }) : null

                            const stateVariant = this.state.activeVariantObj && fieldOjb.modelVariant && model ? model.variants.find(variant => {
                                return variant.name === fieldOjb.modelVariant.name
                            }) : null

                            const defaultModel = get(this.props.pricing[suggestion.State], 'models[0]', null);
                            const defaultVariant = stateModel ? stateModel.variants[0] : get(this.props.pricing[suggestion.State], 'models[0].variants[0]', null);

                            const model = stateModel ? stateModel : defaultModel;
                            
                            const variant = stateVariant ? stateVariant : defaultVariant;

                            this.props.dispatch(actions.updateRepaymentCalcVal('model', model));
                            this.props.dispatch(actions.updateRepaymentCalcVal('modelVariant', variant));

                            this.setState({
                                activeState: suggestion.State,
                                activeModelObj: model,
                                activeVariantObj: variant,
                                postcodeError: false
                            });

                        } else {
                            this.setState({
                                activeState: undefined,
                                qldOverlay: true,
                                postcodeError: true
                            });
                        }
                    } else {
                        this.setState({
                            activeState: undefined,
                            showSummary: false,
                            postcodeError: true
                        });
                    }
                } else {
                    this.setState({
                        activeState: undefined,
                        showSummary: false,
                        postcodeError: false
                    });
                }
            });
        }
    }



    render() {

        const {activeState, activeModel, activeVariant} = this.state;
        const {pricing} = this.props;
        const models = pricing[activeState ? activeState : 'Tasmania'].models.map((model, index) => {return {...model, label: model.name, value: index, variants: model.variants};});
        const model = activeModel ? activeModel : models[0];
        const variants = pricing[activeState ? activeState : 'Tasmania'].models[model.value].variants.map((variant, index) => {return {...variant, label: variant.name, value: index};});
        const variant = activeVariant ? activeVariant : variants[0];

        const travel = [{
            label: '10,000km',
            value: 10000
        },{
            label: '15,000km',
            value: 15000
        },{
            label: '20,000km',
            value: 20000
        },{
            label: '25,000km',
            value: 25000
        }]

        const fields = this.props.repaymentCalculator.fields.map(field => {
            switch (field.type) {
                case 'slider':
                    return (
                        <div className="repayment-calc__field" key={field.id}>
                            <RangeField
                                handleChange={val => this.onFieldChange(field.id, val, field.min)}
                                input={true}
                                {...field} />
                        </div>
                    );
                case 'select':
                    const selectDefault = field.options.find(item => item.default)

                    return (
                        <div className="repayment-calc__field" key={field.id}>
                            <Select
                                options={field.options}
                                onChange={item => this.onFieldChange(field.id, item.value)}
                                defaultOption={selectDefault || field.options[0]}
                                label={field.title}
                            />
                        </div>
                    );
                case 'radio':
                    return (
                        <div className="repayment-calc__field" key={field.id}>
                            <Radio
                                value={`${field.beforeVal && field.beforeVal}${field.format ? field.format(field.value) : field.value}`}
                                id={field.id}
                                options={field.options}
                                onChange={(id, val) => this.onFieldChange(id, val)}
                                label={field.title}
                                defaultOption={0}
                            />
                        </div>
                    );
                case 'text':
                    return (
                        <div className="repayment-calc__field" key={field.id}>
                            <TextField
                                type="text"
                                value={`${field.beforeVal && field.beforeVal}${field.format ? field.format(field.value) : field.value}`}
                                onChange={e => this.onFieldChange(field.id, e.target.value)}
                                disabled={field.disabled}
                                label={field.title}
                            />
                        </div>
                    );
            }
        });
        return (
            <>
                {this.state.qldOverlay &&
                    <QldOverlay
                        onClose={() =>
                            this.setState({
                                qldOverlay: false,
                                postcode: ''
                            })
                        } 
                    /> 
                }
                <div className={`${this.state.postcodeData.length > 0 ? 'repayment-calc' : 'repayment-calc is-loading'}`}>

                    {this.state.postcodeData.length === 0 && <Loader />}
                    <div className={`${this.state.postcodeData.length > 0 ? 'repayment-calc__fields' : 'repayment-calc__fields repayment-calc__disabled'}`}>
                        <TextField
                            type="number"
                            value={this.state.postcode}
                            onChange={e => this.updatePostcode(e.target.value)}
                            label={'To start, enter postcode to view vehicle pricing'}
                            error={this.state.postcodeError ? "Sorry, we don't have pricing for this postcode." : false}
                        />
                    </div>
                    <div className={`${activeState ? '' : 'repayment-calc__disabled'}`}>
                        <div className="repayment-calc__fields">
                            <Select
                                options={models}
                                onChange={model => this.handleModelChange(models.find(i => i.label === model.label))}
                                defaultOption={model}
                                label="Select model"
                            />
                            <Select
                                options={variants}
                                onChange={variant => this.handleVariantChange(variants.find(i => i.label === variant.label))}
                                defaultOption={variant}
                                label="Select model variant"
                            />
                            {fields}
                            <div className="repayment-calc__field-row">
                                {/* {this.props.futurevalue &&
                                    <div className='repayment-calc__field repayment-calc__field-col collapse-bottom'>
                                        <div>
                                            <Select
                                                options={travel}
                                                value={this.state.allowance ? this.state.allowance : null}
                                                onChange={model => this.handleTravelAllowance(model)}
                                                label="Annual travel allowance"
                                            />
                                        </div>
                                    </div>
                                } */}
                                <div className={`repayment-calc__field-col repayment-calc__field${this.state.showSummary ? '' : ' collapse-bottom'}${this.props.futurevalue ? ' repayment-calc__field--margin-top' : ''}`}>
                                    <button
                                        className="cta cta--fill repayment-calc__btn"
                                        onClick={() => this.setState({showSummary: true})}
                                    >Calculate</button>
                                </div>
                            </div>
                        </div>
                        {this.state.showSummary &&
                            <Summary
                                {...this.props.repaymentCalculator}
                                // showGfv={this.props.futurevalue ? true : false}
                                // summary={this.props.repaymentCalculator.repaymentVal}
                                // repayments={this.props.repaymentCalculator.totalRepayments}
                            />
                        }
                        <div className="repayment-calc__footer">
                            <a href="/contact-dealer" className="cta cta--fill">
                                Contact Dealer
                            </a>
                            <a href={this.state.goBackUrl} className="cta cta--back">
                                Go Back
                            </a>
                            {this.props.disclaimer  &&
                                <div>
                                    <span
                                        // href="#"
                                        // onClick={(e) => {
                                        //     // this.setState({showDisclaimer: !this.state.showDisclaimer})
                                        //     e.preventDefault();
                                        // }}
                                        className="repayment-calc__disclaimer-btn">Disclaimer</span>
                                    {
                                        this.state.showDisclaimer && 
                                        <div className="repayment-calc__disclaimer" dangerouslySetInnerHTML={{__html: this.props.disclaimer}} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(state => state)(RepaymentCalculator);
