import loading from './loading';
import { serviceCtas, serviceTermsText } from '../content/service';
import servicingWarranty from './servicingWarranty';
import repaymentCalculator from './repaymentCalculator';
import fields from '../content/repaymentCalculatorFields';

export const initialState = {
    loading: false,
    repaymentCalculator: {
        fields: fields
    },
    servicingWarranty: {
        vehicles: {},
        servicePricing: null,
        tableData: {},
        error: null,
        warrantyContent: {
            logo: null,
            items: [],
            disclaimers: [],
        },
        serviceContent: {
            items: [],
            disclaimers: [],
            // ctas: serviceCtas,
            terms: serviceTermsText
        }
    }
};

export default function reducer(state = initialState, action) {
    return {
        loading: loading(state.loading, action),
        servicingWarranty: servicingWarranty(state.servicingWarranty, action),
        repaymentCalculator: repaymentCalculator(state.repaymentCalculator, action)
    };
}