import React from "react";
import {connect} from 'react-redux';
import { get } from 'lodash';

import * as actions from '../actions/index';

import ModelForm from '../containers/ModelForm';
import InclusionBox from '../components/content/InclusionBox';
import ServiceTable from '../components/content/ServiceTable';
class ServicingWarranty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inclusions: null,
            model: null
        };
        this.modelForm = React.createRef();
    }

    componentDidMount() {
        this.props.dispatch(actions.getServicingWarranty());
    }

    handleSubmit = (values) => {

        // const contentType = this.props.type === 'warranty' ? 'warrantyContent' : 'serviceContent';

        var oldOrNew = '';
        if (values.year < 2019 || values.year === 2019 && values.month < 10 ) {
            oldOrNew = 'old'
        } else {
            oldOrNew = 'new'
        }

        // if (values.year < 2019 || values.year === 2019 && values.month < 10 ) {
        //     this.setState({
        //         inclusions: this.props.servicingWarranty[contentType].oldInclusions
        //     });
        //     this.props.dispatch(actions.getTableData('old', values.model));
        // } else {
        //     this.setState({
        //         inclusions: this.props.servicingWarranty[contentType].newInclusions
        //     });
        //     this.props.dispatch(actions.getTableData('new', values.model));
        // }
        this.props.dispatch(actions.getTableData({year: values.year, month: values.month, oldOrNew}, values.model));
        
        this.setState({
            model: values.model
        });

        // animate
        const modelFormBounds = this.modelForm.current.getBoundingClientRect();
        const navHeight = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('padding-top').replace('px', ''), 10);
        const modelFormTop = modelFormBounds.top + window.pageYOffset + modelFormBounds.height - navHeight;

        // Set delay to make sure contents rendered
        setTimeout(() => {
            $('html, body').animate(
                { scrollTop: modelFormTop }, 300
            );
        }, 101);
    };

    render() {
        console.log('all props', this.props);
        const tableData = this.props.servicingWarranty.tableData;
        const serviceContent = this.props.servicingWarranty.serviceContent;
        const ctas = get(tableData, 'serviceCtas', []); // tableData.type === 'old' ? serviceContent.ctas.old : serviceContent.ctas.new;
        const modelFormTitle = this.props.type === 'servicing'
            ? 'Please select your model to find out more about Suzuki Genuine Servicing*'
            : 'Please select your model to find out more about Suzuki Warranty*';
        return (
            <React.Fragment>
                <div ref={this.modelForm}>
                    <ModelForm
                        title={modelFormTitle}
                        models={get(this.props, 'servicingWarranty.servicePricing.models')}
                        months={get(this.props, 'servicingWarranty.servicePricing.months')}
                        years={get(this.props, 'servicingWarranty.servicePricing.years', []).filter(i => i >= 2019)}
                        onSubmit={this.handleSubmit}
                        error={this.props.servicingWarranty.error}
                    />
                </div>
            
                {this.props.type === 'servicing' && this.props.servicingWarranty.serviceContent.items.length !== 0 ?
                    <React.Fragment>
                        <InclusionBox
                            columns={this.props.servicingWarranty.serviceContent.items.length > 3 ? true : false}
                            {...this.props.servicingWarranty.serviceContent}
                        />

                        <div className="l-section">
                            <div className="l-thin-container text-center">
                                <h2>Suzuki {tableData.model}</h2>
                                <h4>Service Pricing Schedule</h4>
                                <div className="servicing-table">
                                    {tableData.type === 'old' ?
                                        <React.Fragment>
                                            <ServiceTable
                                                model={tableData.model}
                                                data={tableData.table}
                                            />
                                            {tableData.type.length === 0 &&
                                                <span className="servicing-table__error">No infomation available for Suzuki {tableData.model} on this purchase date.</span>
                                            }
                                        </React.Fragment>
                                    :
                                    tableData.table &&
                                    <div className="row">
                                        {tableData.table.turbo.length > 0 &&
                                            <div className={`column small-12 ${tableData.table.nonTurbo.length > 0 && ' '}`}>
                                                <ServiceTable
                                                    model={tableData.model}
                                                    modelType="Turbo Models"
                                                    data={tableData.table.turbo}
                                                />
                                            </div>
                                        }
                                        {tableData.table.nonTurbo.length > 0 &&
                                            <div className={`column small-12 ${tableData.table.turbo.length > 0 && ' '}`}>
                                                <ServiceTable
                                                    model={tableData.model}
                                                    modelType="Non-Turbo Models"
                                                    data={tableData.table.nonTurbo}
                                                />
                                            </div>
                                        }
                                        {(tableData.table.turbo.length === 0 && tableData.table.nonTurbo.length === 0 || !tableData.table) && 
                                            <div className="column">
                                                <span className="servicing-table__error">No infomation available for Suzuki {tableData.model} on this purchase date.</span>
                                            </div>
                                        }
                                    </div>
                                    }
                                    {!tableData.table && 
                                        <div className="row">
                                            <div className="column">
                                                <span className="servicing-table__error">No infomation available for Suzuki {tableData.model} on this purchase date.</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                :
                    this.props.servicingWarranty.warrantyContent.items.length !== 0 &&
                    <InclusionBox
                        columns={this.props.servicingWarranty.warrantyContent.items.length > 3 ? true : false}
                        {...this.props.servicingWarranty.warrantyContent}
                    />
                }

                {/* {this.props.type === 'servicing' && tableData.table &&       
                    <div className="l-section">
                        <div className="cta-section">
                            <div className="cta-section__ctas">
                                {ctas.map((item, index) => (
                                    <a key={index} href={item.href} className="cta cta--fill">{item.text}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                } */}

                {serviceContent.disclaimer &&
                    <div className="l-section">
                        <div className="disclaimer text-center" dangerouslySetInnerHTML={{__html: serviceContent.disclaimer}}/>
                    </div>
                }

                {this.props.type === 'servicing' && tableData.table &&
                    <div className="l-section">
                        <div className="text-center" dangerouslySetInnerHTML={{__html: serviceContent.terms}}/>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default connect(state => state)(ServicingWarranty);
