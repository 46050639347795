import newImage from '../../images/5-Year-544x247.png';
import oldImage from '../../images/5-Year-544x247.png';

const defaultNewService = {
  image: newImage,
  items: [
    'Your Scheduled Maintenance Service costs are capped for 5 Years or 100,000km, whichever occurs first.',
    'Service intervals are every 12 months or 10,000km (for turbo) or 15,000km (for non-turbo), whichever occurs first.',
    'Competitively priced factory-backed servicing.',
    'All service work is undertaken by factory-trained technicians using the latest Suzuki diagnostic equipment.',
    'Enhance your vehicle’s long-term reliability, safety and re-sale value.',
    'Only your Suzuki Dealer can update your vehicle’s factory software.'
  ],
  disclaimers: ['The Suzuki Capped Price Service program applies to standard scheduled services for eligible vehicles for 5 Years or 100,000km, whichever occurs first. Please <a href="/capped-price-service-terms" target="_blank" >click here to view the full terms</a>. The table below shows pricing for 5 Capped Price Services. Contact your Suzuki Service Department for additional information.']
};

const defaultOldService = {
  image: oldImage,
  items: [
    'Your Scheduled Maintenance Service costs are capped for 5 years or 140,000km, whichever occurs first.*',
    "Service intervals are every 6 months or 10,000km, whichever occurs first.",
    "By adhering to your vehicle service schedule, you’re eligible for a 5 year /140,000km extended warranty*.",
    "Competitively priced factory-backed servicing.",
    "All service work is undertaken by factory-trained technicians using the latest Suzuki diagnostic equipment.",
    "Enhance your vehicle’s long-term reliability, safety and re-sale value.",
    "Only your Suzuki Dealer can update your vehicle’s factory software."
  ],
  disclaimers: ["*The Suzuki Capped Priced Service Program is only available to eligible vehicles. Please  <a href='/capped-price-service-terms' target='_blank'>click here to view the full terms and conditions</a>"]
};

const serviceCtas = {
  new: [
    {
      href: '/owners/servicing/frequently-asked-questions',
      text: 'View Faqs'
    },
    {
      href: '/contact-dealer',
      text: 'Contact Dealer'
    },
    {
      href: '/service-booking',
      text: 'Book a service'
    }
  ],
  old: [
    {
      href: '/owners/frequently-asked-questions',
      text: 'View Faqs'
    },
    {
      href: '/contact-dealer',
      text: 'Contact Dealer'
    }, 
    {
      href: '/service-booking',
      text: 'Book a service'
    }
  ]
};

const serviceDisclaimer = '<p>*Should your Suzuki vehicle not be listed please contact your local dealer for more information or consult your Warranty and Service Record Booklet.</a>';

const serviceTermsText = '<p>To view the full terms and conditions of the Capped Price Service Program, <a href="/capped-price-service-terms" target="_blank">click here</a>.</p>';

export { defaultNewService, defaultOldService, serviceCtas, serviceDisclaimer, serviceTermsText };