import React from 'react';
import propTypes from 'prop-types';
import { chunk } from 'lodash';
  
const InclusionBox = ({
  image,
  items,
  disclaimers,
  columns
}) => {
  const inclusionArr = columns ? chunk(items, items.length / 2) : [items];
  return (
  	<div className="inclusion-box">
      <img className="inclusion-box__img" src={image} alt="5 years" />
      <div className="inclusion-box__inner">
        <div className="inclusion-box__row">
          {columns && inclusionArr.length > 1 ?
            inclusionArr.map((array, index) => (
              <div className="inclusion-box__col" key={index}>
                {InclusionList(array)}
              </div>
            ))
          : 
            InclusionList(inclusionArr[0])
          }
        </div>
          {disclaimers.length !== 0 &&
            <div className="inclusion-box__disclaimer">
              {disclaimers.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{__html: item}}/>
              ))}
            </div>
          }
      </div>
    </div>
  );
};

const InclusionList = (items) => (
  <ul className="unstyled inclusion-box__list">
    {items.map((item, index) => (
      <li key={index} dangerouslySetInnerHTML={{__html: item}}/>
    ))}
  </ul>
);
  
InclusionBox.propTypes = {
  image: propTypes.string,
  items: propTypes.array.isRequired,
  disclaimers: propTypes.node,
  columns: propTypes.bool
};
  
InclusionBox.defaultProps = {
  image: null,
  disclaimers: null,
  columns: false
};
  
export default InclusionBox;