import React, {useState} from 'react';
import numberWithCommas from '../../utils/numberWithCommas';

const Summary = ({
    weeklyRepaymentVal,
    monthlyRepaymentVal,
    totalWeeklyRepayments,
    totalMonthlyRepayments,
    weeklyRate,
    monthlyRate,
    totalLoanAmount,
    estFee,
    weeklyPmtIncFee,
    monthlyPmtIncFee,
    weeklyAccKeepingFee,
    monthlyAccKeepingFee,
    gfv,
    showGfv,
    balloon,
    interestRate
}) => {
    const [monthly, setMobile] = useState(false);

    
    return (
        <div className={`repayment-calc__summary${monthly ? ' repayment-calc__summary--monthly' : ' repayment-calc__summary--weekly'}`}>
            <div className="summary__table">
                <div className="summary__table__row">
                    <div className="summary__table__head">Summary</div>
                    <div className="summary__table__head"></div>
                    <div className="summary__table__head"></div>
                </div>
                <div className="summary__table__row">
                    <div className="summary__table__cell">Estimated Loan Amount</div>
                    <div className="summary__table__cell">${numberWithCommas(totalLoanAmount || 0)}</div>
                    <div className="summary__table__cell"></div>
                </div>
                <div className="summary__table__row">
                    <div className="summary__table__cell">Loan Term</div>
                    <div className="summary__table__cell">{totalMonthlyRepayments / 12} {totalMonthlyRepayments === 12 ? 'year' : 'years'}</div>
                    <div className="summary__table__cell"></div>
                </div>
                <div className="summary__table__row">
                    <div className="summary__table__cell">Interest rate p.a.</div>
                    <div className="summary__table__cell">{interestRate}%</div>
                    <div className="summary__table__cell"></div>
                </div>
                {/* <div className="summary__table__row">
                    <div className="summary__table__cell">Establishment fee</div>
                    <div className="summary__table__cell">${numberWithCommas(estFee || 0)}</div>
                    <div className="summary__table__cell"></div>
                </div> */}
                {/* {showGfv &&
                    <div className="summary__table__row">
                        <div className="summary__table__cell">Guaranteed Future Value</div>
                        <div className="summary__table__cell">${numberWithCommas(gfv || 0)}</div>
                        <div className="summary__table__cell"></div>
                    </div>
                } */}
              
                <div className="summary__table__row">
                    <div className="summary__table__cell">Balloon Amount</div>
                    <div className="summary__table__cell">${numberWithCommas(balloon || 0)}</div>
                    <div className="summary__table__cell"></div>
                </div>
                
                <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__head">&nbsp;</div>
                    <div className="summary__table__head">Weekly</div>
                    <div className="summary__table__head">Monthly</div>
                    <span className="summary__table__arrow summary__table__arrow--right" onClick={() => setMobile(!monthly)}></span>
                </div>
                {/* <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__cell">Rate</div>
                    <div className="summary__table__cell">{weeklyRate && weeklyRate.toFixed(2)}%</div>
                    <div className="summary__table__cell">{monthlyRate && monthlyRate.toFixed(2)}%</div>
                </div> */}
                <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__cell">Number of repayments</div>
                    <div className="summary__table__cell">{totalWeeklyRepayments}</div>
                    <div className="summary__table__cell">{totalMonthlyRepayments}</div>
                </div>
                <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__cell">Estimated Repayment<sup>~</sup></div>
                    <div className="summary__table__cell">${numberWithCommas(weeklyRepaymentVal || 0, 2)}</div>
                    <div className="summary__table__cell">${numberWithCommas(monthlyRepaymentVal || 0, 2)}</div>
                </div>
                {/* <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__cell">Account keeping fee*</div>
                    <div className="summary__table__cell">${numberWithCommas(weeklyAccKeepingFee || 0, 2)}</div>
                    <div className="summary__table__cell">${numberWithCommas(monthlyAccKeepingFee || 0, 2)}</div>
                </div>
                <div className="summary__table__row summary__table__row--mobile">
                    <div className="summary__table__cell">Payment including fee</div>
                    <div className="summary__table__cell">${numberWithCommas(weeklyPmtIncFee || 0, 2)}</div>
                    <div className="summary__table__cell">${numberWithCommas(monthlyPmtIncFee || 0, 2)}</div>
                </div> */}
            </div>
        </div>
    );
};

export default Summary;
