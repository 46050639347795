import 'core-js/features/set';
import 'core-js/features/promise';
import 'core-js/features/map';
import 'core-js/features/array';
import "regenerator-runtime/runtime";


// Remove click delay
// import fastclick from 'fastclick';
import ReactApp from './core/App';
// require('dotenv').config();

// if(document && document.body) {
//     fastclick.attach(document.body);
// }

new ReactApp();
